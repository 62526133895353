<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>PickList History</b-breadcrumb-item> </b-breadcrumb
    ><br />
    <b-card title="PickList History">
      <b-col>
        <b-row class="show-on-mobile">
          <b-col cols="6" style="margin-bottom:15px;">
            <b-form-group
              label="Sort By"
              label-for="sort-by-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sort-by-select"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template #first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  :disabled="!sortBy"
                  :options="directionOptions"
                  size="sm"
                  class="w-25"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <!-- Modal for showing Details -->
        <b-modal v-model="showDetailModal" title="Pick List Detail" size="md" ok-only >
          <b-row>
            <b-col cols="6">
              Stock Balance :
            </b-col>
            <b-col cols="6">
              {{detailModal.stockBalance}}
            </b-col>
            <b-col cols="6">
              Warehouse :
            </b-col>
            <b-col cols="6">
              {{detailModal.warehouse}}
            </b-col>
            <b-col cols="6">
              Bin Location :
            </b-col>
            <b-col cols="6">
              {{detailModal.binLocation}}
            </b-col>
            <b-col cols="6">
              Created at :
            </b-col>
            <b-col cols="6">
              {{detailModal.createdAt}}
            </b-col>
            <b-col cols="6">
              Updated at :
            </b-col>
            <b-col cols="6">
              {{detailModal.updatedAt}}
            </b-col>
            <b-col cols="6">
              Order Handler :
            </b-col>
            <b-col cols="6">
              {{detailModal.orderHandler}}
            </b-col>
            <b-col cols="6">
              PickList Line :
            </b-col>
            <b-col cols="6">
              {{detailModal.picklistLine}}
            </b-col>
          </b-row>
          <template #modal-footer>
            <div class="w-100">    
            </div>
          </template>
        </b-modal>
      <b-col cols="12">
        <b-row style="margin-bottom:15px;">
          <b-col cols="4">
            <b-form-group label="Send Type" label-for="form-drum">
                <b-form-select
                  id="selectOption"
                  v-model="selectedSendType"
                  :options="jobList"
                >
                  <template #first>
                  </template>
                </b-form-select>
              </b-form-group>
          </b-col>
          <b-col cols="4" />
          <b-col cols="4">
            <b-form-datepicker
                @input="(value) => fireFiltering(value)"
                placeholder="Search by Date Created"
                v-model="dateCreated"
                style="float: right;  margin-bottom: 30px;"
              />
            <div>
              <b-row>
                <span class="spin" v-if="showSpinner"></span>
              </b-row>
              <!-- Search Box -->
              <b-form-input
                v-model="searchBox"
                type="search"
                placeholder="Type to Search"
                style="float: right;"
              />
              <b-button
                class="primary"
                @click="searchbtn"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom:10px" class="show-on-mobile">
          <b-col>
            <b-form-checkbox
              v-model="stackedStatus"
              value="md"
              unchecked-value="false"
            >
              Stacked Table
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div style="overflow-x: visible;">
              <b-table
                :filter="search"
                hover
                outlined
                head-variant="dark"
                small
                :per-page="perPage"
                :current-page="currentPage"
                :items="picklists"
                :fields="table_fields"
                :stacked="stackedStatus"
                responsive="sm"
              >
                <template v-slot:cell(action)="row">
                  <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                    <template #button-content>
                      <feather-icon size="1x" icon="MenuIcon" />
                    </template>
                    <b-dropdown-item>
                      <b-button
                        variant="success"
                        size="sm"
                        @click="detail(row.item)"
                        style="margin-right:10px"
                        ><feather-icon size="1x" icon="EyeIcon"
                      /></b-button>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="pt-1">
            <b-form-group
              label="Data Per Page"
              label-for="per-page-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="pt-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              class="float-right"
              aria-controls="user-table"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  dateFormat,
  userAccess,
  userAccessManufacture,
  viewAccess,
} from "@/utils/utils.js";

import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      stackedStatus: "md",
      showSpinner: false,
      selectedSendType: null,
      //sort direction list
      dateCreated: null,
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      fieldsPicklist: [
        { key: "orderNumber", label: "SO Number", width:200 },
        { key: "picklistNo", label: "Pick List Number"},
        { key: "lineItemDescription", label: "Line item and Description"},
        { key: "quantity", label: "Quantity" },
        { key: "unit", label: "Unit"},
        { key: "fromBatch", label: "From Batch" },
        { key: "toBatch", label: "To Batch"},
        { key: "cuttingNumber", label: "Cutting Number"},
        { key: "remark", label: "Remark"},
        // { key: "pickListLineNumber", label: "PL Number" },
        { key: "action", label: "Action" },
      ],
      jobList: [
        { value: null, text: "All" },
        { value: "New", text: "New" },
        { value: "Done", text: "Done" },
        { value: "Cancel", text: "Cancel" },
      ],
      showDetailModal: false,
      detailModal: "",
      search: '',
      searchBox: '',
      pageOptions: [5, 10, 20, 100],
      sortBy: "",
      sortDesc: false,
      perPage: 5,
      currentPage: 1,
    };
  },
  methods: {
    ...mapActions({
      picklistData: "picklist/getPicklist",
    }),
    searchbtn() {
      this.search = this.searchBox
      this.loadPicklistData()
    },
    loadPicklistData() {
      this.picklistData({ orderNumber: this.search });
    },
    // add spinner while loading on searching data process
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.search = event.target.value;
      }, 600);
    },
    detail(item){
      var newCreated = dateFormat(item.createdAt).split(",")
      var newUpdated = dateFormat(item.updatedAt).split(",")
      this.detailModal = item,
      this.detailModal.createdAt = newCreated[0] + newCreated[1].replace(/\./g, ':'),
      this.detailModal.updatedAt = newUpdated[0] + newUpdated[1].replace(/\./g, ':'),
      this.showDetailModal=true;
    },
    fireFiltering(value){
      this.dateCreated = value
      this.picklists
    }
  },
  computed: {
    permission() {
      return userAccess("picklist history");
    },
    picklists() {
      var data = this.$store.getters["picklist/getPicklist"].map(
        (el) => {
          return {
            ...el,
            pickListLineNumber: el.picklistNo + "_" + el.picklistLine,
            lineItemDescription: el.lineItem + " " + el.lineDescription,
            statusCheckBox: false,
          };
        }
      );
      if (this.selectedSendType != null) {
        return data.filter(
          (datas) => datas.props.statusType == this.selectedSendType
        );
      }
      if(this.dateCreated != null){
        return data.filter(
          (dates) => dateFormat(dates.createdAt).split(',')[0] == dateFormat(this.dateCreated).split(',')[0]
        )
      }
      return data
    },
    table_fields() {
      return [
        {
          key: "orderNumber",
          label: "SO Number",
          filterable: true,
          sortable: true,
        },
        {
          key: "picklistNo",
          label: "Pick List Number",
          filterable: true,
          sortable: true,
        },
        {
          key: "lineItemDescription",
          label: "Line item and Description",
          filterable: true,
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantity",
          filterable: true,
          sortable: true,
        },
        {
          key: "unit",
          label: "Unit",
          filterable: true,
          sortable: true,
        },
        {
          key: "fromBatch",
          label: "From Batch",
          filterable: true,
          sortable: true,
        },
        {
          key: "toBatch",
          label: "To Batch",
          filterable: true,
          sortable: true,
        },
        {
          key: "cuttingNumber",
          label: "Cutting Number",
          filterable: true,
          sortable: true,
        },
        {
          key: "remark",
          label: "Remark",
          filterable: true,
          sortable: true,
        },
        {
          key: "pickListType",
          label: "Type",
          filterable: true,
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            if(item.pickListType == "C"){
              return "Cutting"
            }else if(item.pickListType == "R"){
              return "Recoil"
            }else if(item.pickListType == "S"){
              return "Direct Sales"
            }else{
              return item.pickListType
            }
          },
        },
        "action",
      ];
    },
    rows() {
      return this.picklists.length;
    },
    // Create an options list from our fields
    sortOptions() {
      return this.fieldsPicklist
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  created() {},
  beforeRouteUpdate(to, from, next) {
    if (
      to.params.someParam !== from.params.someParam ||
      to.query.someQuery !== from.query.someQuery
    ) {
      this.picklistData({ orderNumber: this.search })
        .then(() => {
          next();
        })
        .catch((error) => {
          console.error("Error fetching picklist data:", error);
          next();
        });
    } else {
      next();
    }
  },
  beforeDestroy() {
    this.$store.dispatch("picklist/resetPicklist");
  },
  mounted() {

    if (this.search) {
      this.picklistData({ orderNumber: this.search });
    } else {
      this.$store.dispatch("picklist/resetPicklist");
    }
    
    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPagePicklistHistoryManufacture")) {
      this.perPage = this.$session.get("perPagePicklistHistoryManufacture");
    }
    if (this.$session.has("sortByPicklistHistoryManufacture")) {
      this.sortBy = this.$session.get("sortByPicklistHistoryManufacture");
    }
    if (this.$session.has("sortDescPicklistHistoryManufacture")) {
      this.sortDesc = this.$session.get("sortDescPicklistHistoryManufacture");
    }
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPagePicklistHistoryManufacture", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByPicklistHistoryManufacture", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescPicklistHistoryManufacture", sortDescNew);
    },
  },
};
</script>

<style>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
</style>